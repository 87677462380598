import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useStore from 'src/stores/useStore'
import LoginForm from 'src/components/LoginForm/LoginForm'
import { Flex } from 'src/components/UI/Grid/Grid'

import styles from './login.module.scss'

const LoginPage = () => {
  const { cart } = useStore()
  const router = useRouter()

  const fetchUser = async () => {
    try {
      const response = await cart.getUser()
      if (response) {
        router.push('/profile')
      }
    } catch (e) {}
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <Flex
      paddingX={[5]}
      paddingY={[5]}
      width={1}
      justifyContent="center"
      alignItems="center"
      minHeight={['80vh', null, '80vh']}
    >
      <LoginForm />
    </Flex>
  )
}

export default LoginPage
